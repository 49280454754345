<template>
    <div class="my-5 flex flex-col bg-jiruto-main justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-17 w-auto" src="../assets/logos/jiruto-logo.png" alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                Create a new account
            </h2>
            <p class="mt-2 text-center text-sm leading-5 text-gray-300 max-w">
                Or
                <router-link to="/login" tag="a" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    login to your account
                </router-link>
            </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white mx-2 sm:mx-0 py-8 px-4 shadow rounded-lg sm:px-10">
                <form @submit.prevent="signup">



                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Signup details
                        </h3>
                        <p class="mt-1 text-sm leading-5 text-gray-500">
                            These details are used for logging into Jiruto.
                        </p>
                    </div>

                    <div class="mt-6 input">
                        <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                            Email address
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input id="email"  v-model.trim="email" @focus="emailFocused" @blur="emailBlurred" type="email"
                                   class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 hover:border-indigo-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                   :class="{'border-red-500': $v.email.$error || emailTaken}"
                            />
                        </div>

                        <div v-if="$v.email.$error && focus !== 'email'">
                            <p v-if="!$v.email.required" class="text-red-800 text-sm">You must enter an email</p>
                            <p v-if="!$v.email.email" class="text-red-800 text-sm">This email address is invalid</p>
                        </div>
                        <p v-if="emailTaken" class="text-red-800 text-sm">This email address is unavailable</p>

                    </div>

                    <div class="mt-6 input">
                        <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
                            Password
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input id="password" v-model="password" @focus="passwordFocused" @blur="passwordBlurred" type="password"
                                   class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 hover:border-indigo-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                   :class="{'border-red-500': $v.password.$error, 'border-jiruto-successDarker': !$v.password.$error && password.trim().length !== 0}"
                            />
                        </div>
                        <div v-if="$v.password.$error && focus !== 'password'">
                            <p v-if="!$v.password.required" class="text-red-800 text-sm">You must enter a password</p>
                            <p v-if="!$v.password.strongPassword && $v.password.required" class="text-red-800 text-sm">Password should have 1 lowercase, 1 uppercase, 1 digit, 1 special character and be atleast 8 characters long</p>
                        </div>
                    </div>

                    <div class="mt-8 border-t border-gray-200 pt-8">
                        <div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Profile Details
                            </h3>
                            <p class="mt-1 text-sm leading-5 text-gray-500">
                                Your profile and communities names use these fields.
                            </p>
                        </div>

                        <div >
                            <label for="username" class="mt-6 block text-sm font-medium leading-5 text-gray-700">
                                Username
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input  id="username" v-model.trim="username" @focus="usernameFocused" @blur="usernameBlurred" type="text"
                                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 hover:border-indigo-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                        :class="{'border-red-500': $v.username.$error || usernameColor === 'red',
                                    'border-jiruto-successDarker': !$v.username.$error && usernameColor === 'green'}"
                                />
                            </div>
                            <div v-if="$v.username.$error && focus !== 'email'">
                                <p v-if="!$v.username.required" class="text-red-800 text-sm">Username field is required</p>
                                <p v-if="!$v.username.minLength && $v.username.required" class="text-red-800 text-sm">3 characters minimum</p>
                            </div>
                            <div v-if="$v.username.required">
                                <p v-if="usernameColor === 'red'" class="text-red-500 text-sm">* Username {{ username }} is unavailable </p>
                                <p v-if="usernameColor === 'green'" class="text-jiruto-successDarker text-sm">* Username {{ username }} is available </p>
                                <!--                            <p v-else class="text-jiruto-success text-sm">* {{ username }} is free to use! </p>-->
                            </div>
                        </div>

                        <div class="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6 gap-4">
                            <div class="sm:col-span-3">
                                <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">
                                    First name
                                </label>
                                <div class="mt-1 rounded-md shadow-sm">
                                    <input type="text" v-model="firstName" id="first_name" @focus="firstNameFocused" @blur="firstNameBlurred"
                                           class="border-gray-300 bg-white rounded-md hover:border-indigo-600 block w-full sm:text-sm sm:leading-5 placeholder-gray-500 focus:bg-white"
                                           :class="{'border-red-500': $v.firstName.$error}"
                                    />
                                </div>
                                <div v-if="$v.firstName.$error && focus !== 'firstName'">
                                    <p v-if="!$v.firstName.required" class="text-red-800 text-sm">* First name required</p>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">
                                    Last name
                                </label>
                                <div class="mt-1 rounded-md shadow-sm">
                                    <input type="text" v-model="lastName" id="last_name" @focus="lastNameFocused" @blur="lastNameBlurred"
                                           class="border-gray-300 bg-white rounded-md hover:border-indigo-600 block w-full sm:text-sm sm:leading-5 placeholder-gray-500 focus:bg-white"
                                           :class="{'border-red-500': $v.lastName.$error}"
                                    />
                                </div>
                                <div v-if="$v.lastName.$error && focus !== 'lastName'">
                                    <p v-if="!$v.lastName.required" class="text-red-800 text-sm">* First name required</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="mt-10">
                        <span class="block w-full rounded-md shadow-sm">
                            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                Sign up
                            </button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import * as axios from '../axios-auth.js'
    import { required, email, minLength } from 'vuelidate/lib/validators'
    export default {
        name: "SignUp",
        data() {
            return {
                email: '',
                username: '',
                password: '',
                currentRequest: null,
                validUsername: null,
                emailTaken: false,
                firstName: '',
                lastName: '',
                focus: null,

            }
        },
        methods: {
            signup() {
                this.$v.$touch()
                if(!this.$v.$invalid && this.validUsername){
                    const userSignup = {
                        email: this.email,
                        password: this.password,
                        source_id: 0,
                        username: this.username,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        createdAt: Date.now()
                    }
                    console.log("Going to show the loader now!")
                    let loader = this.$loading.show({
                        onCancel: this.loaderCanceled,
                    });
                    this.$store.dispatch('signup', userSignup).then( () => {
                        loader.hide()
                        this.$router.push({name: 'home'}).then().catch((err) => {console.error("Some Error with pushing", err)})
                    }, err => {
                        loader.hide()
                        if(err.response.data.error_type === "email"){
                            this.emailTaken = true
                        }
                    }).catch(err => {
                        loader.hide()
                        console.log("Error while singing up:", err.response.data)
                    })
                }
            },
            loaderCanceled(){
                console.log("loader canceled")
            },
            usernameFocused(){
                this.focus = "username"
            },
            usernameBlurred(){
                this.focus = null;
                this.$v.username.$touch()
            },
            passwordFocused(){
                this.focus = "password"
            },
            passwordBlurred(){
                this.focus = null;
                this.$v.password.$touch()
            },
            emailFocused(){
                this.focus = "email"
            },
            emailBlurred(){
                this.focus = null;
                this.$v.email.$touch()
            },
            firstNameFocused(){
                this.focus = "firstName"
            },
            firstNameBlurred(){
                this.focus = null;
                this.$v.firstName.$touch()
            },
            lastNameFocused(){
                this.focus = "lastName"
            },
            lastNameBlurred(){
                this.focus = null;
                this.$v.lastName.$touch()
            }

        },
        validations: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
                email,
            },
            username: {
                required,
                minLength: minLength(3)
            },
            password: {
                required,
                minLength: minLength(8),
                strongPassword(password1) {
                    return (
                        /[a-z]/.test(password1) && // checks for a-z
                        /[A-Z]/.test(password1) && // checks for a-z
                        /[0-9]/.test(password1) && // checks for 0-9
                        /\W|_/.test(password1) && // checks for special char
                        password1.length >= 8
                    );
                }
            }
        },
        watch: {
            username(){
                if(this.username.length > 2 ) {
                    this.validUsername = null
                    clearTimeout(this.currentRequest)
                    if(this.username === ""){
                        this.validUsername = true
                    }

                    if(this.username.trim() !== ""){
                        this.currentRequest =  setTimeout(() => {
                            axios.jigo.get(`/v2/user/isUsernameValid`, {validateStatus: (status) =>{
                                    return status < 500
                                }, params: {username: this.username}})
                                .then((resp) => {
                                this.validUsername = resp.data
                            }).catch( (err) => {
                                this.validUsername = false;
                                console.error(err)
                            })
                        }, 500)
                    }
                }
            },
            email(){
                this.emailTaken = false
            }
        },
        computed: {
            usernameColor(){
                if (this.validUsername === null){
                    return "none"
                }else {
                    return this.validUsername ? "green" : "red"
                }
            }
        }
    }
</script>

<style scoped>
.input.invalid input {
    border: 1px solid red;
    background-color: beige;
}
</style>
